import React from "react";
import Logo from "../assets/logo.png";

function Header() {
  return (
    <header>
      <div className="title">
        <img src={Logo} alt="Logo" />
        <h1>Surgical Radiography</h1>
      </div>
    </header>
  );
}

export default Header;
